<template>
    <div class="admin_page_body">
        <div class="admin_page_item pr0">
            <div class="data_search">
                <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="84px">
                    <el-form-item label="用户昵称：" prop="name">
                        <el-input v-model="listQuery.name" placeholder="请输入用户昵称"></el-input>
                    </el-form-item>

                    <el-form-item label="用户手机号：" prop="phone">
                        <el-input v-model="listQuery.phone" placeholder="请输入用户手机号"></el-input>
                    </el-form-item>

                    <el-form-item class="last">
                        <el-button type="primary" :loading="isSearchLoading"  @click="toSearchList">查 询</el-button>
                        <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="data_group mt18">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="$router.push('/admin/user/add')">添加用户</el-button>
            </div>

            <div class="data_table mt24">
                <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading">
                    <el-table-column type="index" width="50" label="#" align="center"></el-table-column>
                    <el-table-column prop="nickname" label="用户昵称" min-width="100"></el-table-column>
                    <el-table-column prop="phone" label="用户手机号" min-width="200" align="center"></el-table-column>
                    <el-table-column prop="role" label="角色名称" min-width="240px" align="center"></el-table-column>
                    <el-table-column label="状态" min-width="100px" align="center">
                        <template slot-scope="scope">
                            <div class="status info" v-if="scope.row.status === 1">未审核</div>
                            <div class="status yellow" v-if="scope.row.status === 2">注销待审核</div>
                            <div class="status red" v-if="scope.row.status === 3">已注销</div>
                            <div class="status yellow" v-if="scope.row.status === 4">审核中</div>
                            <div class="status red" v-if="scope.row.status === 5">审核失败</div>
                            <div class="status green" v-if="scope.row.status === 6">审核通过</div>
                            <div class="status yellow" v-if="scope.row.status === 7">修改审核</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="80" fixed="right">
                        <template>
                            <el-button type="text">关闭</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <pagination :size="listQuery.size" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
  import {getAdminUser} from '../../../api/admin/user'
  import {mapState} from "vuex";
  const pagination = () => import('../../../components/pagination/admin')
  export default {
    name: "adminUserIndex",
    data () {
      return {
        listQuery: {
          name: '',
          phone: '',
          page: 1,
          size: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        isSearchLoading: false,
        isRequestLoading: false,
        listData: []
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.$refs['listQuery'].resetFields()
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getAdminUser(this.listQuery).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.records
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.size = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      cancelData () {
        this.$confirm('此操作将永久关闭该供应单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          // postSupplyCancel(id).then(res => {
          //   if (res.code === 0) {
          //     this.$message({
          //       message: '供应单关闭成功',
          //       type: 'success',
          //       showClose: true,
          //     });
          //     this.getList()
          //   }
          // })
        }).catch(() => {});
      }
    },
    components: {pagination}
  }
</script>
