import {request} from '@/utils/request'

// 列表
export function getAdminUser(data) {
  return request('GET','/shop/userManagement/page', data, true)
}

// 添加
export function postAdminUser(data) {
  return request('POST','/shop/userManagement', data, true)
}

// 编辑
export function putAdminUser(data) {
  return request('PUT','/shop/userManagement', data, true)
}

// 删除
export function delAdminUser(id) {
  return request('PUT','/shop/userManagement/' + id, '', true)
}

